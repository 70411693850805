<template>
  <footer
    class="container bg-fourthiary py-6 px-8 flex justify-between rounded-full mx-auto text-fifthiary"
  >
    <span>Follow me</span>
    <ul class="flex gap-4">
      <li class="hover:brightness-75">
        <a target="_blank" href="https://github.com/keremyurttas">
          <img
            width="24"
            height="24"
            class="cursor-pointer"
            src="../assets/icons/github.svg"
            alt="GitHub logo"
          />
        </a>
      </li>
      <li class="hover:brightness-75">
        <a target="_blank" href="https://www.linkedin.com/in/keremyurttas/"
          ><img
            width="24"
            height="24"
            class="cursor-pointer"
            src="../assets/icons/linkedin.svg"
            alt="LinkedIn logo"
        /></a>
      </li>
    </ul>
  </footer>
</template>
